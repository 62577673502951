import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Nav from "../components/nav"
import Img from "gatsby-image"

class GivingPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      window.DonorBox = { widgetLinkClassName: "custom-dbox-popup" }
    }
  }

  render() {
    const { data } = this.props

    return (
      <Layout>
        <SEO
          title="Donate | Kingdom Kids"
          description="Would you consider supporting us financially to reach more kids with the love of God? Our team members volunteer their time and effort for this cause so that 100% of donations can go directly to this ministry."
        />
        <Nav />
        <section className="kdk-giving-header kdk-top-section">
          <div className="container">
            <h1>Donate to the mission</h1>
            <div className="columns">
              <div className="column kdk-giving-header__content">
                <p className="text-lead">
                  We believe in the value and importance of investing in our
                  local community
                </p>
                <p>
                  Would you consider supporting us financially to reach more
                  kids with the love of God? Our team members volunteer their
                  time and effort for this cause so that 100% of donations can
                  go directly to this ministry.
                </p>
                <p>
                  If you'd like to set up a recurring donation, we will create
                  an account where you can easily change your donation amount or
                  cancel.
                </p>
                <p>
                  <strong>Bank Account</strong>
                  <p>
                    BSB: 302-162
                    <br />
                    Account #: 169936-7
                  </p>
                </p>
              </div>
              <div className="column kdk-giving-header__donate-form">
                <iframe
                  src="https://kingdom-kids.raisely.com/embed/"
                  width="100%"
                  height="600"
                  border="0"
                  style={{
                    border: 0,
                  }}
                ></iframe>
              </div>
            </div>
            <div className="kdk-giving-header__donate">
              <h3>Donate items</h3>
              <p>
                Please contact us if you have any food, clothing, or toys that
                you would like to donate to our kids and families who are in
                need.{" "}
              </p>
              <a href="mailto:info@kingdomkids.org.au" className="btn-default">
                Contact Us
              </a>
            </div>
          </div>
        </section>

        <section className="kdk-giving-top">
          <div className="container">
            <p className="text-lead">Your donations help us to provide:</p>
            <div className="columns is-multiline">
              <div className="column is-one-third kdk-giving-top-item">
                <div className="kdk-giving-top-item__image">
                  <Img fluid={data.birthdayImage.childImageSharp.fluid} />
                </div>
                <h2>Birthday Parties</h2>
              </div>
              <div className="column is-one-third kdk-giving-top-item">
                <div className="kdk-giving-top-item__image">
                  <Img fluid={data.identityImage.childImageSharp.fluid} />
                </div>
                <h2>Identity Packs</h2>
              </div>
              <div className="column is-one-third kdk-giving-top-item">
                <div className="kdk-giving-top-item__image">
                  <Img fluid={data.biblesImage.childImageSharp.fluid} />
                </div>
                <h2>Resources</h2>
              </div>
              <div className="column is-one-third kdk-giving-top-item">
                <div className="kdk-giving-top-item__image">
                  <Img fluid={data.bbqImage.childImageSharp.fluid} />
                </div>
                <h2>BBQ Giveaways</h2>
              </div>
              <div className="column is-one-third kdk-giving-top-item">
                <div className="kdk-giving-top-item__image">
                  <Img fluid={data.hampersImage.childImageSharp.fluid} />
                </div>
                <h2>Gift Hampers</h2>
              </div>
              <div className="column is-one-third kdk-giving-top-item">
                <div className="kdk-giving-top-item__image">
                  <Img fluid={data.afternoonTeaImage.childImageSharp.fluid} />
                </div>
                <h2>Afternoon Tea</h2>
              </div>
            </div>
            <p className="text-lead">
              &amp; more... We are committed to doing life with our kingdom kid
              families
            </p>
          </div>
        </section>
      </Layout>
    )
  }
}

export default GivingPage

export const pageQuery = graphql`
  query GivingPageQuery {
    site {
      siteMetadata {
        title
      }
    }
    birthdayImage: file(relativePath: { eq: "birthday-parties.png" }) {
      childImageSharp {
        fluid(maxWidth: 530, traceSVG: { color: "#fff100" }) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    identityImage: file(relativePath: { eq: "identity-packs.png" }) {
      childImageSharp {
        fluid(maxWidth: 530, traceSVG: { color: "#000000" }) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    biblesImage: file(relativePath: { eq: "bibles-resources.png" }) {
      childImageSharp {
        fluid(maxWidth: 530, traceSVG: { color: "#F92CCB" }) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    bbqImage: file(relativePath: { eq: "bbq-giveaways.png" }) {
      childImageSharp {
        fluid(maxWidth: 530, traceSVG: { color: "#fff100" }) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    hampersImage: file(relativePath: { eq: "gift-hampers.png" }) {
      childImageSharp {
        fluid(maxWidth: 530, traceSVG: { color: "#000000" }) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    afternoonTeaImage: file(relativePath: { eq: "afternoon-tea.png" }) {
      childImageSharp {
        fluid(maxWidth: 530, traceSVG: { color: "#F92CCB" }) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`
